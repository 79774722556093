import styled from 'styled-components';

export const HeroSlideContainer = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
`;

export const HeroBackgroundFlex = styled.div`
  width: 100%;
  height: 80rem;
  overflow: hidden;

  @media screen and (min-width: 56.25em) {
    display: flex;
    height: 55rem;
  }
`;

export const HeroBackgroundImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  height: 50%;

  & > img {
    object-fit: cover;
  }

  @media screen and (min-width: 56.25em) {
    max-width: 50%;
    flex-basis: 50%;
    height: 100%;
  }
`;

export const HeroTransparentBg = styled.div`
  background-color: rgb(var(--color-white), 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%) translateY(-50%);
  border-radius: 3rem;
  padding: 2rem 3rem;
  backdrop-filter: blur(4.5px);
  width: 75%;

  @media screen and (max-width: 20rem) {
    width: 80%;
  }

  @media screen and (min-width: 56.25em) {
    display: flex;
    align-items: center;
    max-width: 85rem;
  }
`;

export const HeroSlideProgramData = styled.div`
  flex: 45%;
  display: block;
  min-height: 100%;

  & > p {
    font-weight: 700;
    font-size: 2.4rem;
    max-width: max-content;
    border-bottom: 2px solid rgb(var(--color-primary));
    margin: 2rem auto;

    @media screen and (max-width: 28.4375em) {
      font-size: 2rem;
    }

    @media screen and (min-width: 56.25em) {
      text-align: right;
      font-size: 2.6rem;
      border: none;
    }
  }
`;

export const HeroSlideLogoContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 16rem;
  aspect-ratio: 2/1;

  & > img {
    object-fit: contain;
  }

  @media screen and (max-width: 28.4375em) {
    width: 14rem;
  }

  @media screen and (min-width: 56.25em) {
    width: 23rem;
  }
`;

export const HeroSlideDetailsContainer = styled.div`
  font-weight: 600;
  position: relative;
  padding: 0 2rem;

  & > * {
    padding: 1rem 0;
  }

  & > button {
    margin: 4rem auto 1rem;
    display: block;
    width: max-content;
    padding: 1rem 2rem;
  }

  & > hr {
    border-bottom: 2px solid rgb(var(--color-primary));
    padding: 0;
    width: 10rem;
    margin: 1rem auto;
  }

  @media screen and (min-width: 56.25em) {
    border-left: 2px solid rgb(var(--color-primary));
    flex-basis: 75%;
    margin-left: 2rem;
  }
`;

export const HeroSlideHeaderText = styled.p<{ isSubHeading?: boolean }>`
  font-weight: 700;
  font-size: 2rem;

  ${({ isSubHeading }) =>
    isSubHeading &&
    `
    font-size: 1.8rem;
    
`}

  @media screen and (min-width: 50em) {
    font-size: 2.4rem;

    ${({ isSubHeading }) =>
      isSubHeading &&
      `
    font-size: 2.2rem;
    
`}
  }
`;

export const DiscountCircle = styled.div`
  width: 9rem;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: rgb(var(--color-secondary));
  color: rgb(var(--color-black));
  font-weight: 700;
  display: grid;
  place-items: center;
  position: absolute;
  right: -3rem;

  & > p {
    text-align: center;
    font-size: 1.1rem;
  }
  @media screen and (max-width: 24.25em) {
    bottom: 5rem;
  }

  @media screen and (min-width: 50em) {
    font-size: 1.6rem;
    right: -6rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;
