import Image from 'next/image';

import { Button, ShowView } from '@gate-academy/react';
import {
  DiscountCircle,
  HeroBackgroundFlex,
  HeroBackgroundImageContainer,
  HeroSlideContainer,
  HeroSlideDetailsContainer,
  HeroSlideHeaderText,
  HeroSlideLogoContainer,
  HeroSlideProgramData,
  HeroTransparentBg,
} from './summer-program-hero-slide.styles';

import { useRouter } from 'next/router';
import routes from '../../lib/routes';
import { useEffect, useState } from 'react';

const redirectToUS = () => {
  const offset = new Date().getTimezoneOffset();

  // is the offset +/- 3 hours
  return Math.abs(offset) > 180;
};

const SummerProgramHeroSlide = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  const router = useRouter();

  const handleButtonClick = () => {
    if (redirectToUS()) {
      router.push(routes.usSummerProgram());
    } else {
      router.push(routes.summerProgram());
    }
  };

  return (
    <HeroSlideContainer>
      <HeroBackgroundFlex>
        <HeroBackgroundImageContainer>
          <Image
            src="/boy-photo.png"
            alt="a boy wearing a virtual reality head gear standing and pointing"
            fill
          />
        </HeroBackgroundImageContainer>

        <HeroBackgroundImageContainer>
          <Image
            src="/girl-writing.png"
            alt="a girl sitting and writing "
            fill
          />
        </HeroBackgroundImageContainer>
      </HeroBackgroundFlex>

      <HeroTransparentBg>
        <HeroSlideProgramData>
          <HeroSlideLogoContainer>
            <Image src="/aspire-logo.png" alt="aspire logo" fill />
          </HeroSlideLogoContainer>
          <p>Summer Groups 2024</p>
        </HeroSlideProgramData>

        <HeroSlideDetailsContainer>
          <HeroSlideHeaderText>
            Our summer classes are open for registration!
          </HeroSlideHeaderText>
          <ShowView when={isMounted}>
            <HeroSlideHeaderText isSubHeading>
              {redirectToUS()
                ? 'Test Prep and Grade level Courses'
                : 'Test & Exam Prep Courses'}
            </HeroSlideHeaderText>
          </ShowView>

          <hr />

          <ShowView when={isMounted}>
            <p>
              {redirectToUS()
                ? 'SAT, ACT, Grade 9, 10, 11 and 12, African Languages and French'
                : 'Entrance Exams, IGCSE, GCSE, SAT, ACT, WAEC & NECO'}
            </p>
          </ShowView>

          <hr />

          <DiscountCircle>
            <p>up to 10% off additional learners and courses</p>
          </DiscountCircle>
          <Button onClick={handleButtonClick}>FIND A COURSE</Button>
        </HeroSlideDetailsContainer>
      </HeroTransparentBg>
    </HeroSlideContainer>
  );
};

export default SummerProgramHeroSlide;
